import { proxy } from 'valtio'
import {
  CommonApi,
  ExchangeProgramPartnerSchoolOption,
  ExchangeProgramPartnerSchoolOptionPartnerTypeEnum,
} from '../../generated'
import { apiConfig } from '../apiConfig'
import { isJa } from '../i18n/useLang'

export const partnerSchoolStore = proxy<{
  items: Array<
    ExchangeProgramPartnerSchoolOption & {
      code: string
      value: string
      isOther: boolean
    }
  >
}>({
  items: [],
})

export function getPartnerType(type: ExchangeProgramPartnerSchoolOptionPartnerTypeEnum) {
  if (!isJa()) return type
  switch (type) {
    case ExchangeProgramPartnerSchoolOptionPartnerTypeEnum.Academic:
      return '大学院'
    default:
      return '大学'
  }
}

const api = new CommonApi(apiConfig)
export async function loadPartnerSchools() {
  await api.getExchangeProgramPartnerSchools().then((result) => {
    partnerSchoolStore.items = result.map((r) => ({
      ...r,
      code: String(r.id),
      value: String(r.id),
      isOther: r.id === 285,
    }))
  })
}
