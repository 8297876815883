import { proxy } from 'valtio'
import { CommonApi, Option } from '../../generated'
import { apiConfig } from '../apiConfig'
import { isJa } from '../i18n/useLang'
import { getName } from '../util/getName'

export const educationTypesStore = proxy<{ items: Option[] }>({
  items: [],
})

const api = new CommonApi(apiConfig)
export async function loadEducationTypes() {
  await api.getEducationTypes().then((result) => {
    educationTypesStore.items = result
  })
}

export function getEducationTypesName(id?: number, defaultValue?: string) {
  // その他が0なのでundefinedで判断
  if (id === undefined) return ''
  return getName(educationTypesStore.items, id, defaultValue)
}

export function getEducationTypesOptions(educationTypes: { items: Option[] }) {
  const key = isJa() ? 'nameJa' : 'name'
  const filtered = educationTypes.items.filter((i) => [4, 5, 6].includes(i.id))
  return filtered.map((e) => ({
    ...e,
    label: e[key],
  }))
}
