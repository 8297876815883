import { proxy } from 'valtio'
import { CommonApi, ExchangeProgramDepartment, Option } from '../../generated'
import { apiConfig } from '../apiConfig'
import { isJa } from '../i18n/useLang'

export const departmentsStore = proxy<{ items: ExchangeProgramDepartment[] }>({
  items: [],
})

const api = new CommonApi(apiConfig)
export async function loadDepartments() {
  await api.getExchangeProgramDepartments().then((result) => {
    departmentsStore.items = result
  })
}

export function getDepartmentOptions(
  departments: {
    items: ExchangeProgramDepartment[]
  },
  majorId?: number
): Option[] {
  const filtered = departments.items.filter((d) => d.exchangeProgramMajorId === majorId)
  return filtered.map((d) => ({
    id: d.id,
    code: d.departmentCode,
    name: d.name ?? '',
    nameJa: d.nameJa,
    isOther: false,
  }))
}

export function getDepartmentName(id?: number) {
  if (!id) return ''
  const key = isJa() ? 'nameJa' : 'name'
  const find = departmentsStore.items.find((d) => d.id === id)
  return find ? find[key] : ''
}

export function getExchangeProgramMajorId(id?: number) {
  if (!id) return 0
  const find = departmentsStore.items.find((d) => d.id === id)
  return find ? find.exchangeProgramMajorId : 0
}

export function getDepartmentSyllabus(id?: number) {
  if (!id) return undefined
  const find = departmentsStore.items.find((d) => d.id === id)
  return find ? (isJa() ? find.syllabusUrlJp : find.syllabusUrl) : undefined
}
