import * as Sentry from '@sentry/browser'
import { Dedupe, ExtraErrorData } from '@sentry/integrations'
import { Integrations } from '@sentry/tracing'
import React from 'react'
import { render } from 'react-dom'
import { ErrorBoundary } from '../common/components/ErrorBoundary'
import '../index.css'
import { ApplicantIndex } from './index'

if (!import.meta.env.DEV && import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN as string,
    release: import.meta.env.VITE_SENTRY_RELEASE as string,
    integrations: [new Integrations.BrowserTracing(), new ExtraErrorData(), new Dedupe()],
  })
}

render(
  <ErrorBoundary>
    <ApplicantIndex />
  </ErrorBoundary>,
  document.getElementById('root')
)
