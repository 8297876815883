import { Center, FormControl, FormErrorMessage, FormLabel, Input, Stack } from '@chakra-ui/react'
import React, { FC, useCallback } from 'react'
import { SubmitErrorHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useTitle } from 'react-use'
import { useLocation } from 'wouter'
import { apiConfig } from '../../../common/apiConfig'
import { TextLink } from '../../../common/components/TextLink'
import { FormControlWrapper } from '../../../common/form/FormInput'
import { ApplicantPasswordsApi } from '../../../generated'
import { Button } from '../Button'
import { Title } from '../Title'

interface FormType {
  email: string
}

export const PasswordResetRequestForm: FC = () => {
  const [t] = useTranslation()
  useTitle(t('SEPAS パスワードリセット'))

  const { control, handleSubmit, setError } = useForm<FormType>({
    mode: 'onBlur',
  })

  const [, setLocation] = useLocation()

  const reset = useCallback(
    (form) => {
      new ApplicantPasswordsApi(apiConfig)
        .createResetRequest({ email: form.email })
        .then(() => {
          setLocation('/password-reset-request-complete')
        })
        .catch((error) => {
          switch (error.status) {
            case 404:
              setError('email', {
                type: 'manual',
                message: 'メールアドレスが見つかりませんでした。',
              })
              break
            default:
              console.log('error', error)
          }
        })
    },
    [setError, setLocation]
  )

  const onError: SubmitErrorHandler<FormType> = useCallback((error, event) => {
    console.info('submit error', error, event)
  }, [])

  return (
    <div>
      <Title text={t('パスワードリセット')} />

      <div className={'mt24'}>
        {t('メールアドレスを入力してください。パスワードをリセットするURLをメールに送信します。')}
      </div>

      <div className={'mt24'}>
        <FormControlWrapper<FormType>
          control={control}
          path={'email'}
          skipNormalize={true}
          options={{
            required: '必須入力です。',
            pattern: {
              value:
                /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
              message: 'メールアドレスを入力してください。',
            },
          }}
        >
          {({ id, isInvalid, error, ...inputProps }) => (
            <FormControl
              id={id}
              isRequired={true}
              isInvalid={isInvalid ? true : undefined}
              as={'fieldset'}
              display={'flex'}
              flexDir={'row'}
              alignItems={`start`}
            >
              <FormLabel w={'10rem'} fontSize={`14px`} fontWeight={700}>
                {t('メールアドレス')}
              </FormLabel>
              <Stack>
                <Input
                  type="email"
                  size={'sm'}
                  w={'24rem'}
                  autoComplete={'email'}
                  {...inputProps}
                  maxLength={200}
                />
                {error?.message && <FormErrorMessage>{t(error.message)}</FormErrorMessage>}
              </Stack>
            </FormControl>
          )}
        </FormControlWrapper>

        <Center className={'mt16'}>
          <Button
            role={'primary'}
            size={'md'}
            text={t('送信')}
            w={'200px'}
            onClick={handleSubmit(reset, onError)}
          />
        </Center>

        <Center className={'mt8'}>
          <TextLink text={t('キャンセル')} to={'/'} />
        </Center>
      </div>
    </div>
  )
}
