/* tslint:disable */
/* eslint-disable */
/**
 * My App API
 * My App API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExchangeProgramMajor
 */
export interface ExchangeProgramMajor {
    /**
     * 
     * @type {number}
     * @memberof ExchangeProgramMajor
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ExchangeProgramMajor
     */
    majorCode: string;
    /**
     * 
     * @type {string}
     * @memberof ExchangeProgramMajor
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ExchangeProgramMajor
     */
    nameJa: string;
    /**
     * 
     * @type {string}
     * @memberof ExchangeProgramMajor
     */
    degreeType: ExchangeProgramMajorDegreeTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ExchangeProgramMajor
     */
    syllabusUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ExchangeProgramMajor
     */
    syllabusUrlJp?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ExchangeProgramMajor
     */
    needToReadPdf: boolean;
}

/**
* @export
* @enum {string}
*/
export enum ExchangeProgramMajorDegreeTypeEnum {
    Undergraduate = 'Undergraduate',
    Graduate = 'Graduate'
}

export function ExchangeProgramMajorFromJSON(json: any): ExchangeProgramMajor {
    return ExchangeProgramMajorFromJSONTyped(json, false);
}

export function ExchangeProgramMajorFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExchangeProgramMajor {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'majorCode': json['majorCode'],
        'name': json['name'],
        'nameJa': json['nameJa'],
        'degreeType': json['degreeType'],
        'syllabusUrl': !exists(json, 'syllabusUrl') ? undefined : json['syllabusUrl'],
        'syllabusUrlJp': !exists(json, 'syllabusUrlJp') ? undefined : json['syllabusUrlJp'],
        'needToReadPdf': json['needToReadPdf'],
    };
}

export function ExchangeProgramMajorToJSON(value?: ExchangeProgramMajor | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'majorCode': value.majorCode,
        'name': value.name,
        'nameJa': value.nameJa,
        'degreeType': value.degreeType,
        'syllabusUrl': value.syllabusUrl,
        'syllabusUrlJp': value.syllabusUrlJp,
        'needToReadPdf': value.needToReadPdf,
    };
}


