import { proxy } from 'valtio'
import { CommonApi, Option } from '../../generated'
import { apiConfig } from '../apiConfig'
import { isJa } from '../i18n/useLang'
import { getName } from '../util/getName'

export const languageLevelStore = proxy<{ english: Option[], japanese: Option[] }>({
  english: [],
  japanese: []
})

const api = new CommonApi(apiConfig)

export async function loadEnglishLevels() {
  await api.getEnglishLevels().then((result) => {
    languageLevelStore.english = result
  })
}
export async function loadJapaneseLevels() {
  await api.getJapaneseLevels().then((result) => {
    languageLevelStore.japanese = result
  })
}

export function getEnglishLevelsName(id?: number, defaultValue?: string) {
  // その他が0なのでundefinedで判断
  if (id === undefined) return ''
  return getName(languageLevelStore.english, id, defaultValue)
}

export function getJapaneseLevelsName(id?: number, defaultValue?: string) {
  // その他が0なのでundefinedで判断
  if (id === undefined) return ''
  return getName(languageLevelStore.japanese, id, defaultValue)
}

export function getLanguageLevelsOptions(language: Option[]) {
  const key = isJa() ? 'nameJa' : 'name'
  return language.map((e) => ({
    ...e,
    label: e[key],
  }))
}
