import { ColorEnums, HoverColorEnums } from './ColorEnums'

export function useButtonStyle(
  type: 'primary' | 'destructive' | 'secondary' | 'warning' | 'disable' | 'link'
) {
  const primary = {
    bg: ColorEnums.Primary,
    _hover: {
      bg: HoverColorEnums.Primary,
    },
  }

  const destructive = {
    bg: '#fff!important',
    color: ColorEnums.Destructive,
    borderColor: ColorEnums.Destructive,
    variant: 'outline',
    _hover: {
      color: HoverColorEnums.Destructive,
      borderColor: HoverColorEnums.Destructive,
    },
  }

  const warning = {
    bg: ColorEnums.Warning,
    _hover: {
      bg: HoverColorEnums.Warning,
    },
  }

  const secondary = {
    bg: '#fff!important',
    color: ColorEnums.Secondary,
    borderColor: ColorEnums.Secondary,
    variant: 'outline',
    _hover: {
      color: HoverColorEnums.Secondary,
      borderColor: HoverColorEnums.Secondary,
    },
  }

  const disable = {
    bg: ColorEnums.Disable,
    _hover: {
      bg: HoverColorEnums.Disable,
    },
  }

  const link = {
    bg: 'none',
    color: '#008569',
    fontWeight: 400,
    _hover: {
      textDecoration: 'underline',
    },
  }

  let activeColor
  switch (type) {
    case 'primary':
      activeColor = primary
      break
    case 'secondary':
      activeColor = secondary
      break
    case 'destructive':
      activeColor = destructive
      break
    case 'warning':
      activeColor = warning
      break
    case 'disable':
      activeColor = disable
      break
    case 'link':
      activeColor = link
      break
  }

  return {
    borderRadius: '0',
    color: '#fff',
    ...activeColor,
  }
}
