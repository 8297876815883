import {
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
  useToast,
} from '@chakra-ui/react'
import React, { FC, useCallback } from 'react'
import { SubmitErrorHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useTitle } from 'react-use'
import { useLocation } from 'wouter'
import { apiConfig } from '../../../common/apiConfig'
import { Container } from '../../../common/components/Container'
import { TextLink } from '../../../common/components/TextLink'
import { FormControlWrapper } from '../../../common/form/FormInput'
import { ApplicantProfileApi, ChangeEmailRequest } from '../../../generated'
import { Button } from '../Button'
import { Title } from '../Title'

export const EmailChangeRequestForm: FC = () => {
  const [t] = useTranslation()
  useTitle(t('SEPAS メールアドレス変更'))
  const toast = useToast()
  const { handleSubmit, control } = useForm<ChangeEmailRequest>({
    mode: 'onBlur',
  })
  const [, setLocation] = useLocation()

  const registerPassword = useCallback(
    (form) => {
      new ApplicantProfileApi(apiConfig)
        .requestChangeEmail(form)
        .then(() => {
          setLocation('/email-change-request-complete')
        })
        .catch((error) => {
          switch (error.status) {
            case 404:
              setLocation('/')
              break
            case 409:
              toast({
                title: t('メールアドレス変更に失敗しました。'),
                description: t('既に使用されているメールアドレスです。'),
                status: `error`,
                duration: 6000,
                isClosable: true,
              })
              // メールアドレスが今と同じ、または、他の申請者のアドレスと衝突したとき。409 Conflict
              console.log('error', error)
              break
            default:
              console.log('error', error)
          }
        })
    },
    [t, setLocation, toast]
  )

  const onError: SubmitErrorHandler<ChangeEmailRequest> = useCallback((error, event) => {
    console.info('submit error', error, event)
  }, [])

  return (
    <Container innerWidth={'800px'}>
      <div>
        <Title text={t('メールアドレス変更')} />

        <div className={'mt24'}>
          {t('新しいメールアドレスに登録情報変更のためのURLが送信されます。')}
        </div>

        <div className={'mt24'}>
          <Stack>
            <FormControlWrapper<ChangeEmailRequest>
              control={control}
              path={'email'}
              skipNormalize={true}
              options={{
                required: '必須入力です。',
                pattern: {
                  value:
                    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
                  message: 'メールアドレスを入力してください。',
                },
              }}
            >
              {({ id, isInvalid, error, ...inputProps }) => (
                <FormControl
                  id={id}
                  isRequired={true}
                  isInvalid={isInvalid ? true : undefined}
                  as={'fieldset'}
                  display={'flex'}
                  flexDir={'row'}
                  alignItems={`start`}
                >
                  <FormLabel w={'10rem'} fontSize={`14px`} fontWeight={700}>
                    {t('新しいメールアドレス')}
                  </FormLabel>
                  <Stack>
                    <Input
                      type="email"
                      size={'sm'}
                      w={'24rem'}
                      autoComplete={'email'}
                      {...inputProps}
                      maxLength={200}
                    />
                    {error?.message && <FormErrorMessage>{t(error.message)}</FormErrorMessage>}
                  </Stack>
                </FormControl>
              )}
            </FormControlWrapper>
          </Stack>

          <Center className={'mt16'}>
            <Button
              role={'primary'}
              size={'md'}
              text={t('メールアドレス変更')}
              w={'200px'}
              onClick={handleSubmit(registerPassword, onError)}
            />
          </Center>

          <Center className={'mt8'}>
            <TextLink text={t('キャンセル')} to={'/applications'} />
          </Center>
        </div>
      </div>
    </Container>
  )
}
