import { Flex, Link, Text } from '@chakra-ui/react'
import React, { VFC } from 'react'

export const Footer: VFC = () => {
  return (
    <Flex
      as={'footer'}
      w={'100%'}
      backgroundColor={'#85023E'}
      borderTop={'1px solid #596e94'}
      h={'44px'}
      justifyContent={'center'}
      align={'center'}
    >
      <Link href={'https://www.kyushu-u.ac.jp/'} _hover={{ textDecoration: 'none' }}>
        <Text color={'#FFF'} fontSize={'24px'} fontFamily={'Yu Mincho, YuMincho'} fontWeight={500}>
          九州大学
        </Text>
      </Link>
    </Flex>
  )
}
