/* tslint:disable */
/* eslint-disable */
/**
 * My App API
 * My App API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Attachment,
    AttachmentFromJSON,
    AttachmentFromJSONTyped,
    AttachmentToJSON,
} from './';

/**
 * 
 * @export
 * @interface StudyPlan
 */
export interface StudyPlan {
    /**
     * 
     * @type {number}
     * @memberof StudyPlan
     */
    attachmentId: number;
    /**
     * 
     * @type {Attachment}
     * @memberof StudyPlan
     */
    attachment?: Attachment;
}

export function StudyPlanFromJSON(json: any): StudyPlan {
    return StudyPlanFromJSONTyped(json, false);
}

export function StudyPlanFromJSONTyped(json: any, ignoreDiscriminator: boolean): StudyPlan {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attachmentId': json['attachmentId'],
        'attachment': !exists(json, 'attachment') ? undefined : AttachmentFromJSON(json['attachment']),
    };
}

export function StudyPlanToJSON(value?: StudyPlan | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attachmentId': value.attachmentId,
        'attachment': AttachmentToJSON(value.attachment),
    };
}


