import { proxy } from 'valtio'
import {
  AnticipatedEnrollmentProgramOptionCodeEnum,
  ApplicationResponseAnticipatedEnrollmentProgramEnum,
  CommonApi,
  Option,
} from '../../generated'
import { apiConfig } from '../apiConfig'
import { isJa } from '../i18n/useLang'
import { getName } from '../util/getName'

export const programsStore = proxy<{ items: Option[] }>({
  items: [],
})

const api = new CommonApi(apiConfig)
export async function loadPrograms() {
  await api.getAnticipatedEnrollmentPrograms().then((result) => {
    programsStore.items = result.map((r, i) => ({
      id: i,
      code: r.code,
      name: r.name,
      nameJa: r.nameJa,
      isOther: false,
    }))
  })
}

export function getProgramsName(id?: number, defaultValue?: string, isOther?: boolean) {
  if (id === undefined) return ``
  const items: Option[] = []
  programsStore.items.forEach((p: Option) => items.push(p))
  return getName(items, id, defaultValue, isOther)
}

export function getProgramOptions(options: Option[]) {
  const key = isJa() ? 'nameJa' : 'name'
  return options.map((o) => ({
    ...o,
    label: o[key],
  }))
}

export function getProgramName(v?: ApplicationResponseAnticipatedEnrollmentProgramEnum) {
  if (!v) return ''
  const key = isJa() ? 'nameJa' : 'name'
  let program: AnticipatedEnrollmentProgramOptionCodeEnum
  switch (v) {
    case ApplicationResponseAnticipatedEnrollmentProgramEnum.Jtw:
      program = AnticipatedEnrollmentProgramOptionCodeEnum.Jtw
      break
    case ApplicationResponseAnticipatedEnrollmentProgramEnum.Jlcc:
      program = AnticipatedEnrollmentProgramOptionCodeEnum.Jlcc
      break
    case ApplicationResponseAnticipatedEnrollmentProgramEnum.GakubuGakufu:
      program = AnticipatedEnrollmentProgramOptionCodeEnum.GakubuGakufu
      break
    case ApplicationResponseAnticipatedEnrollmentProgramEnum.Qpels:
      program = AnticipatedEnrollmentProgramOptionCodeEnum.Qpels
      break
    default:
      return ''
  }
  const item = programsStore.items.find((p) => p.code === program)
  return item ? item[key] : ''
}
