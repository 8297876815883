import { Link, LinkProps } from '@chakra-ui/react'
import React, { FC } from 'react'
import { Link as WouterLink } from 'wouter'

interface TextLinkProps extends LinkProps {
  text: string
  to?: string
  target?: string
}

export const TextLink: FC<TextLinkProps> = (props) => {
  return (
    <Link as={WouterLink} color="#008569" {...props}>
      {props.text}
    </Link>
  )
}
