import React, { FC, memo } from 'react'
import isEqual from 'react-fast-compare'
import classes from './title.module.css'
import clsx from 'clsx'

interface TitleProps {
  id?: string
  text: string
  className?: string
  level?: 'h1' | 'h2' | 'h3'
}

const _Title: FC<TitleProps> = (props) => {
  switch (props.level) {
    case 'h1':
      return <H1 {...props} />
    case 'h2':
      return <H2 {...props} />
    case 'h3':
      return <H3 {...props} />
    default:
      return <H1 {...props} />
  }
}
export const Title = memo(_Title, isEqual)

const H1: FC<TitleProps> = ({ id, text, className }) => {
  const cn = clsx(classes.h1, className)
  return (
    <div id={id} className={cn}>
      {text}
    </div>
  )
}

const H2: FC<TitleProps> = ({ id, text, className }) => {
  const cn = clsx(classes.h2, className)
  return (
    <div id={id} className={cn}>
      {text}
    </div>
  )
}

const H3: FC<TitleProps> = ({ id, text, className }) => {
  const cn = clsx(classes.h3, className)
  return (
    <div id={id} className={cn}>
      {text}
    </div>
  )
}
