import { Alert, AlertIcon, Box, VStack } from '@chakra-ui/react'
import React, { VFC } from 'react'
import { useTitle } from 'react-use'
import { useLang } from '../../common/i18n/useLang'
import { Footer } from '../components/Footer'
import { LoginForm } from '../components/login/LoginForm'
import { useApplicantSession } from '../hooks/useApplicant'

const Login: VFC = () => {
  const { t } = useLang()
  useTitle(`SEPAS ${t('ログイン')}`)
  const state = useApplicantSession()
  return (
    <VStack position={'relative'} w={'100%'} h={'calc(100vh - 44px - 100px)'}>
      {state === 'terminated' && (
        <Alert status={'error'} w={'800px'} m={'2rem auto 0'} p={6}>
          <AlertIcon />
          {t('セッションが切断されました')}
        </Alert>
      )}
      <Box
        w={'800px'}
        h={'calc(100vh - 44px - 100px - 44px)'}
        minH={'600px'}
        marginX={'auto'}
        pt={'24px'}
        px={'16px'}
        pb={'48px'}
      >
        <LoginForm />
      </Box>
      <Footer />
    </VStack>
  )
}

export default Login
