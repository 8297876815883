/* tslint:disable */
/* eslint-disable */
/**
 * My App API
 * My App API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AnticipatedEnrollmentTermOption
 */
export interface AnticipatedEnrollmentTermOption {
    /**
     * 
     * @type {string}
     * @memberof AnticipatedEnrollmentTermOption
     */
    code: AnticipatedEnrollmentTermOptionCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof AnticipatedEnrollmentTermOption
     */
    program: AnticipatedEnrollmentTermOptionProgramEnum;
    /**
     * 
     * @type {string}
     * @memberof AnticipatedEnrollmentTermOption
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AnticipatedEnrollmentTermOption
     */
    nameJa: string;
}

/**
* @export
* @enum {string}
*/
export enum AnticipatedEnrollmentTermOptionCodeEnum {
    SpringFall = 'SpringFall',
    FallSpring = 'FallSpring',
    Spring = 'Spring',
    Fall = 'Fall',
    FallSpringForJtw = 'FallSpringForJTW',
    FallForJtw = 'FallForJTW',
    SpringForJtw = 'SpringForJTW',
    FallSpringForJlcc = 'FallSpringForJLCC',
    ArrangeForQpels = 'ArrangeForQPELS',
    FallForQpels = 'FallForQPELS',
    SpringForQpels = 'SpringForQPELS',
    FallSpringForQpels = 'FallSpringForQPELS'
}/**
* @export
* @enum {string}
*/
export enum AnticipatedEnrollmentTermOptionProgramEnum {
    GakubuGakufu = 'GakubuGakufu',
    Jtw = 'JTW',
    Jlcc = 'JLCC',
    Qpels = 'QPELS'
}

export function AnticipatedEnrollmentTermOptionFromJSON(json: any): AnticipatedEnrollmentTermOption {
    return AnticipatedEnrollmentTermOptionFromJSONTyped(json, false);
}

export function AnticipatedEnrollmentTermOptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnticipatedEnrollmentTermOption {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': json['code'],
        'program': json['program'],
        'name': json['name'],
        'nameJa': json['nameJa'],
    };
}

export function AnticipatedEnrollmentTermOptionToJSON(value?: AnticipatedEnrollmentTermOption | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'program': value.program,
        'name': value.name,
        'nameJa': value.nameJa,
    };
}


