import { proxy } from 'valtio'
import {
  CommonApi,
  ExchangeProgramMajor,
  ExchangeProgramMajorDegreeTypeEnum,
  Option,
} from '../../generated'
import { apiConfig } from '../apiConfig'
import { isJa } from '../i18n/useLang'

export const majorsStore = proxy<{
  items: Array<
    ExchangeProgramMajor & {
      code: string
      value: string
      isOther: boolean
    }
  >
}>({
  items: [],
})

const api = new CommonApi(apiConfig)
export async function loadMajors() {
  await api.getExchangeProgramMajors().then((result) => {
    majorsStore.items = result.map((r) => ({
      ...r,
      code: r.majorCode,
      value: r.majorCode,
      isOther: false,
    }))
  })
}

export function getDegreeType(id?: number): ExchangeProgramMajorDegreeTypeEnum | undefined {
  if (!id) return undefined
  const find = majorsStore.items.find((m) => m.id === id)
  return find?.degreeType ?? undefined
}

export function getMajorName(id?: number) {
  if (!id) return ''
  const key = isJa() ? 'nameJa' : 'name'
  const find = majorsStore.items.find((m) => m.id === id)
  return find ? find[key] : ''
}

export function getMajorSyllabus(id?: number) {
  if (!id) return undefined
  const find = majorsStore.items.find((m) => m.id === id)
  return find ? (isJa() ? find.syllabusUrlJp : find.syllabusUrl) : undefined
}

export function getMajorOptions(majors: { items: Option[] }) {
  const key = isJa() ? 'nameJa' : 'name'
  return majors.items.map((m) => ({
    ...m,
    label: m[key],
  }))
}
