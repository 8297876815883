import {proxy} from 'valtio'
import {
  AnticipatedEnrollmentTermOption,
  AnticipatedEnrollmentTermOptionCodeEnum,
  AnticipatedEnrollmentTermOptionProgramEnum,
  ApplicationResponseAnticipatedEnrollmentProgramEnum,
  ApplicationResponseAnticipatedEnrollmentTermEnum,
  CommonApi,
} from '../../generated'
import {apiConfig} from '../apiConfig'
import {formatDate, isJa} from '../i18n/useLang'

export const programTermsStore = proxy<{
  items: Array<
    AnticipatedEnrollmentTermOption & {
      id: number
      label: string
      value: string
      isOther: boolean
    }
  >
}>({
  items: [],
})

const api = new CommonApi(apiConfig)
export async function loadProgramTerms() {
  const key = isJa() ? 'nameJa' : 'name'
  await api.getAnticipatedEnrollmentTerms().then((result) => {
    programTermsStore.items = result.map((r, i) => ({
      ...r,
      id: i,
      value: r.code,
      label: r[key],
      isOther: false,
    }))
  })
}

export function getProgramTermOptions(
  selected?: ApplicationResponseAnticipatedEnrollmentProgramEnum
) {
  if (!selected) return []
  let program: AnticipatedEnrollmentTermOptionProgramEnum
  switch (selected) {
    case ApplicationResponseAnticipatedEnrollmentProgramEnum.GakubuGakufu:
      program = AnticipatedEnrollmentTermOptionProgramEnum.GakubuGakufu
      break
    case ApplicationResponseAnticipatedEnrollmentProgramEnum.Jlcc:
      program = AnticipatedEnrollmentTermOptionProgramEnum.Jlcc
      break
    case ApplicationResponseAnticipatedEnrollmentProgramEnum.Jtw:
      program = AnticipatedEnrollmentTermOptionProgramEnum.Jtw
      break
    case ApplicationResponseAnticipatedEnrollmentProgramEnum.Qpels:
      program = AnticipatedEnrollmentTermOptionProgramEnum.Qpels
      break
    default:
      return []
  }
  return programTermsStore.items.filter((t) => t.program === program)
}

export function getProgramTermName(v?: ApplicationResponseAnticipatedEnrollmentTermEnum) {
  if (!v) return ''
  const key = isJa() ? 'nameJa' : 'name'
  let term: AnticipatedEnrollmentTermOptionCodeEnum
  switch (v) {
    case ApplicationResponseAnticipatedEnrollmentTermEnum.Fall:
      term = AnticipatedEnrollmentTermOptionCodeEnum.Fall
      break
    case ApplicationResponseAnticipatedEnrollmentTermEnum.FallSpring:
      term = AnticipatedEnrollmentTermOptionCodeEnum.FallSpring
      break
    case ApplicationResponseAnticipatedEnrollmentTermEnum.Spring:
      term = AnticipatedEnrollmentTermOptionCodeEnum.Spring
      break
    case ApplicationResponseAnticipatedEnrollmentTermEnum.SpringFall:
      term = AnticipatedEnrollmentTermOptionCodeEnum.SpringFall
      break
    case ApplicationResponseAnticipatedEnrollmentTermEnum.FallSpringForJtw:
      term = AnticipatedEnrollmentTermOptionCodeEnum.FallSpringForJtw
      break
    case ApplicationResponseAnticipatedEnrollmentTermEnum.FallForJtw:
      term = AnticipatedEnrollmentTermOptionCodeEnum.FallForJtw
      break
    case ApplicationResponseAnticipatedEnrollmentTermEnum.SpringForJtw:
      term = AnticipatedEnrollmentTermOptionCodeEnum.SpringForJtw
      break
    case ApplicationResponseAnticipatedEnrollmentTermEnum.FallSpringForJlcc:
      term = AnticipatedEnrollmentTermOptionCodeEnum.FallSpringForJlcc
      break
    case ApplicationResponseAnticipatedEnrollmentTermEnum.ArrangeForQpels:
      term = AnticipatedEnrollmentTermOptionCodeEnum.ArrangeForQpels
      break
    case ApplicationResponseAnticipatedEnrollmentTermEnum.FallForQpels:
      term = AnticipatedEnrollmentTermOptionCodeEnum.FallForQpels
      break
    case ApplicationResponseAnticipatedEnrollmentTermEnum.SpringForQpels:
      term = AnticipatedEnrollmentTermOptionCodeEnum.SpringForQpels
      break
    case ApplicationResponseAnticipatedEnrollmentTermEnum.FallSpringForQpels:
      term = AnticipatedEnrollmentTermOptionCodeEnum.FallSpringForQpels
      break
    default:
      return ''
  }
  const item = programTermsStore.items.find((t) => t.code === term)
  return item ? item[key] : ''
}

export function convertTermToDate(term?: ApplicationResponseAnticipatedEnrollmentTermEnum, sentAt?: Date): {
  from?: Date
  to?: Date
} {
  const date = sentAt ?? new Date()
  const year = date.getFullYear()
  let termFrom: Date
  let termTo: Date
  switch (term) {
    case ApplicationResponseAnticipatedEnrollmentTermEnum.SpringFall:
      termFrom = new Date(year + 1, 3)
      termTo = new Date(year + 2, 2)
      break
    case ApplicationResponseAnticipatedEnrollmentTermEnum.Spring:
      termFrom = new Date(year + 1, 3)
      termTo = new Date(year + 1, 8)
      break
    case ApplicationResponseAnticipatedEnrollmentTermEnum.FallSpring:
      termFrom = new Date(year, 9)
      termTo = new Date(year + 1, 8)
      break
    case ApplicationResponseAnticipatedEnrollmentTermEnum.Fall:
      termFrom = new Date(year, 9)
      termTo = new Date(year + 1, 2)
      break
    case ApplicationResponseAnticipatedEnrollmentTermEnum.FallForJtw:
      termFrom = new Date(year, 9)
      termTo = new Date(year + 1, 1)
      break
    case ApplicationResponseAnticipatedEnrollmentTermEnum.SpringForJtw:
      termFrom = new Date(year + 1, 3)
      termTo = new Date(year + 1, 6)
      break
    case ApplicationResponseAnticipatedEnrollmentTermEnum.FallSpringForJtw:
      termFrom = new Date(year, 9)
      termTo = new Date(year + 1, 6)
      break
    case ApplicationResponseAnticipatedEnrollmentTermEnum.FallSpringForJlcc:
      termFrom = new Date(year, 9)
      termTo = new Date(year + 1, 7)
      break
    // case ApplicationResponseAnticipatedEnrollmentTermEnum.ArrangeForQpels:
    //   termFrom = undefined
    //   termTo = undefined
    //   break
    case ApplicationResponseAnticipatedEnrollmentTermEnum.SpringForQpels:
      termFrom = new Date(year + 1, 3)
      termTo = new Date(year + 1, 8)
      break
    case ApplicationResponseAnticipatedEnrollmentTermEnum.FallForQpels:
      termFrom = new Date(year, 9)
      termTo = new Date(year + 1, 2)
      break
    case ApplicationResponseAnticipatedEnrollmentTermEnum.FallSpringForQpels:
      termFrom = new Date(year, 9)
      termTo = new Date(year + 1, 8)
      break
    default:
      return { from: undefined, to: undefined }
  }
  return { from: termFrom, to: termTo }
}

export function getTermDate(term?: ApplicationResponseAnticipatedEnrollmentTermEnum, sentAt?: Date) {
  if (term == ApplicationResponseAnticipatedEnrollmentTermEnum.ArrangeForQpels) {
    // TODO('バックエンドのOptionsControllerから名前の取得')
    return 'Arrange with a host faculty member(s)'
  }
  const date = convertTermToDate(term, sentAt)
  return isJa()
    ? `${formatDate(date.from, 'MM/yyyy')} - ${formatDate(date.to, 'MM/yyyy')}`
    : `${formatDate(date.from, 'LLL, yyyy')} - ${formatDate(date.to, 'LLL, yyyy')}`
}
