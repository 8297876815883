import { useCallback } from 'react'
import { loadCountries } from './CountriesStore'
import { loadDepartments } from './DepartmentsStore'
import { loadEducationTypes } from './EducationTypesStore'
import { loadEnglishLevels, loadJapaneseLevels } from "./LanguageLevelStore";
import { loadMajors } from './MajorsStore'
import { loadPartnerSchools } from './PartnerSchoolStore'
import { loadPartnerSchoolTypes } from './PartnerSchoolTypeStore'
import { loadPrograms } from './ProgramsStore'
import { loadProgramTerms } from './ProgramTermsStore'
import { loadSupplementalMaterialTypes } from './SupplementalMaterialTypesStore'

export function useOptionStores() {
  const loadOptions = useCallback(async () => {
    loadEducationTypes().then()
    // loadEducationalSystems().then()
    // loadCourses().then()
    loadCountries().then()
    loadPartnerSchoolTypes().then()
    loadPrograms().then()
    loadProgramTerms().then()
    loadMajors().then()
    loadDepartments().then()
    loadPartnerSchools().then()
    // loadScholarshipFinancialSupports().then()
    // loadScholarshipStatuses().then()
    // loadScholarshipTypes().then()
    loadSupplementalMaterialTypes().then()
    loadEnglishLevels().then()
    loadJapaneseLevels().then()
  }, [])

  return { loadOptions }
}
